<template>
    <div class="home">
      <!-- <h1>首页</h1> -->
      <div class="shuffling">
        <el-carousel indicator-position="none" :height="carousel+'px'">
          <el-carousel-item v-for="item in home_img" :key="item.id">
            <div><img :src="item" alt="" ref="imgs"></div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content">
        <div class="positioning">
          <img src="https://img.youstarplanet.com/website/home/positioning.png" alt="">
          <div>
            <span>定位</span>
            <span>共建共享中国演艺文化数字产业新格局</span>
          </div>
        </div>
        <div class="value">
          <img src="https://img.youstarplanet.com/website/home/value.png" alt="">
          <div>
            <span>价值</span>
            <span>以产业链优化供应链</span>
            <span>以数据链赋能价值链</span>
            <span>以创新链升级内容链</span>
            <span>以生态链激活保障链</span>
          </div>
        </div>
        <div class="mission">
          <div class="mission_img">
            <el-carousel>
              <el-carousel-item v-for="item in mission_img" :key="item.id">
                <div><img :src="item" alt=""></div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div>
            <span>使命</span>
            <span>举旗帜、聚民心、育新人、兴文化、展形象</span>
          </div>
        </div>
        <div class="vision">
          <img src="https://img.youstarplanet.com/website/home/vision.png" alt="">
          <div>
            <span>愿景</span>
            <span>让世界倾听中国声音</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default{
  data() {
    return {
      home_img:[
        "https://img.youstarplanet.com/website/home/shuffling1.jpg",
        "https://img.youstarplanet.com/website/home/shuffling2.jpg",
        "https://img.youstarplanet.com/website/home/shuffling3.jpg",
      ], 
      mission_img:[
        "https://img.youstarplanet.com/website/home/mission1.jpg",
        "https://img.youstarplanet.com/website/home/mission2.jpg",
        "https://img.youstarplanet.com/website/home/mission3.jpg",
        "https://img.youstarplanet.com/website/home/mission4.jpg",
        "https://img.youstarplanet.com/website/home/mission5.jpg",
      ], 
      carousel:0, //轮播图高度
    }
  },
  created() {
    // 获取到轮播图的初始值
    setTimeout(() => {
      this.carousel = window.innerWidth*80/192
    }, 0); 
  },
  async mounted() {
    document.documentElement.scrollTop = 0

    this.$nextTick(()=>{
        window.addEventListener('resize', () => { //监听浏览器窗口大小改变
           this.carousel = window.innerWidth*80/192
        });
    })
  },
}
</script>

<style lang="less">
.home{
  .shuffling{
    width: 100%;
    .el-carousel__container{
      width: 100%;
      object-fit:cover;
      // height: 500px;
    }
    .el-carousel__item.is-animating{
      text-align: center;
    }
    .el-carousel__item {
      img{
        width: 100%;
        object-fit:cover;
      }
    }
  }
  .content{
    margin-top: 42px;
    // margin-bottom: 200px;
    .positioning{
      width: 1270px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 42px;
      img{
        // height: 705px;
        width: 100%;
        object-fit:cover;
      }
      &>div{
        position: absolute;
        top: 100px;
        left: 65px;
        span{
          display: block;
          font-size: 24px;
          color: #FFFFFF;
          &:nth-child(1){
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 20px;
          }
        }
      }
    }
    .value{
      position: relative;
      margin-bottom: 42px;
      img{
        // height: 900px;
        width: 100%;
        object-fit:cover;
      }
      &>div{
        position: absolute;
        top: 130px;
        left: 18%;
        span{
          display: block;
          font-size: 24px;
          color: #FFFFFF;
          margin-bottom: 10px;
          &:nth-child(1){
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 20px;
          }
        }
      }
    }
    .mission{
      width: 1270px;
      height: 706px;
      margin: 0 auto;
      margin-bottom: 42px;
      display: flex;
      .mission_img{
        width: 50%;
        height: 100%;
        .el-carousel__container{
          width: 100%;
          object-fit:cover;
          height: 706px !important;
        }
        .el-carousel__item.is-animating{
          text-align: center;
        }
        .el-carousel__item {
          img{
            width: 100%;
            object-fit:cover;
          }
        }
      }
      &>div{
        width: 50%;
        background-color: #FBFBFB;
        span{
          display: block;
          font-size: 24px;
          margin-left: 100px;
          &:nth-child(1){
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 20px;
            margin-top: 250px;
          }
        }
      }
    }
    .vision{
      position: relative;
      img{
        // height: 900px;
        width: 100%;
        object-fit:cover;
      }
      &>div{
        position: absolute;
        top: 130px;
        left: 18%;
        span{
          display: block;
          font-size: 24px;
          color: #FFFFFF;
          &:nth-child(1){
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 20px;
          }
        }
      }
    }
    
  }
} 
</style>
